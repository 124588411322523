import { Toast } from "vant";
export default function urlblobDownload(url, fileName) {
  if (url) {
    Toast.loading({
      message: "文件下载中...",
      forbidClick: true,
      loadingType: "spinner",
    });

    fetch(url, {
      responseType: "blob",
    })
      .then((res) => {
        Toast.clear();
        return res.blob();
      })
      .then((blob) => {
        Toast.clear();

        blobFileDownload(blob, fileName);
        Toast("下载成功！");
      })
      .catch((e) => {
        Toast.clear();
        Toast("下载失败！");
      });
  } else {
    Toast("当前附件暂无下载链接!");
  }
}

export function blobFileDownload(blob, fileName) {
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(blobUrl);
}
