//办事项表单模板
const templates = {
  ItemSimpleApply: () => import("./ItemSimpleApply"), //常规办事
  SkilledPersonSubsidy: () => import("./SkilledPersonSubsidy"), //技能人才补贴
  SkilledPersonRecognition: () => import("./SkilledPersonRecognition"), //技能人才认定
  TalentRecruitSubsidy: () => import("./TalentRecruitSubsidy"), //人才应聘补贴
  YoungHouseSubsidy: () => import("./YoungHouseSubsidy"), //青年人才购房补贴
  HighHouseSubsidy: () => import("./HighHouseSubsidy"), //高层次人才购房补贴
  YoungLifeSubsidy: () => import("./YoungLifeSubsidy"), //青年人才生活补贴（非全日制）
  HighTalentDevote: () => import("./HighTalentDevote"), //高层次人才经济贡献奖
  TalentGreenApply: () => import("./TalentGreenApply"), //人才绿卡申请
  TalentHouseApply: () => import("./TalentHouseApply"), //人才公寓租赁申请
  EduOneSubsidy: () => import("./EduOneSubsidy"), //优秀教育人才一次性奖励申领
  EduLifeSubsidy: () => import("./EduLifeSubsidy"), //优秀教育人才生活补贴申领
  EduPlatformSubsidy: () => import("./EduPlatformSubsidy"), //优秀教育人才平台资助申领

  // YoungRentSubsidy: () => import("./YoungRentSubsidy"), //青年人才租房补贴
  IndustryHouseSubsidy: () => import("./IndustryHouseSubsidy"), //产业人才购房补贴
  EduHealthHouseSubsidy: () => import("./EduHealthHouseSubsidy"), //教育医疗人才购房补贴
  IndustryTalentAward: () => import("./IndustryTalentAward"), //产业人才经济贡献奖
  IndustryTalentSchool: () => import("./IndustryTalentSchool"), //产业人才子女入学
  HighLevelTalentSchool: () => import("./HighLevelTalentSchool"), //产业人才子女入学
  HighFreeRent: () => import("./HighFreeRent"), //高层次人才免租住房
  IndustryFreeRent: () => import("./IndustryFreeRent"), //产业人才免租住房
  YoungHouseSubsidyZz2017: () => import("./YoungHouseSubsidyZz2017"), //青年人才购房补贴2017
  YoungHouseSubsidyZz2022: () => import("./YoungHouseSubsidyZz2022"), //青年人才购房补贴2022

  TalentFlatMhfl: () => import("./TalentFlatMhfl"), //人才公寓梅河芳邻
  TalentStageMhfl: () => import("./TalentStageMhfl"), //人才驿站梅河芳邻
  TalentFlatHt: () => import("./TalentFlatHt"), //人才公寓航田
  ShareStationHt: () => import("./ShareStationHt"), //共享工位航田
  TalentRecruitSubsidyGraduate: () => import("./TalentRecruitSubsidyGraduate"), //青年人才应聘补贴（应届生版本）

  EducationTalentSettleDown: () => import("./EducationTalentSettleDown"), //学历人才安家补贴
  DoctorServiceLiveSubsidy: () => import("./DoctorServiceLiveSubsidy") //学历人才安家补贴

};
export default templates;
