<template>
  <div class="page-wrapper">
    <div class="page-title">
      {{ detail ? detail.itemName : "" }}
    </div>
    <div v-if="enable">
      <van-steps :active="active">
        <van-step>填写申请表</van-step>
        <van-step>上传材料</van-step>
      </van-steps>
    </div>

    <div v-show="active == 0">
      <div class="notices" v-if="detail && notices[detail.formKey]">
        <p class="notice-item">填写说明</p>
        <p
          class="notice-item"
          v-for="item in notices[detail.formKey]"
          :key="item"
        >
          {{ item }}
        </p>
      </div>

      <component
        ref="form"
        :is="componentId"
        :detail="detail"
        :defaultForm="defaultForm"
        v-if="loaded"
        @authCheck="handleAuthCheck"
      ></component>
    </div>

    <div v-show="active == 1">
      <van-field-upload
        ref="uploadForm"
        :materialList="defaultMaterialList"
        :uploadSize="2"
      ></van-field-upload>
    </div>

    <div v-if="enable">
      <div class="btns" v-show="active == 0">
        <van-button block type="primary" round @click="handleNext">
          下一步
        </van-button>
      </div>
      <div class="btns" v-show="active == 1">
        <van-button
          block
          round
          @click="active = 0"
          style="margin-right: 0.2rem"
        >
          上一步
        </van-button>
        <van-button
          block
          type="primary"
          :loading="isLoading"
          loading-text="加载中"
          round
          @click="handleSubmit"
        >
          提交
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getFormDetail,
  getApplyInfo,
  postApply,
  getApplyDetailInfo,
} from "@/api/policy/apply";
import templates from "./templates";
import VanFieldUpload from "@/components/VanFieldUpload";
export default {
  data() {
    return {
      active: 0,
      componentId: "",
      detail: null,
      materialList: [], //材料
      hisMaterialList: [], //历史材料
      notices: {
        high_house_subsidy: [
          "1、购买住房地址需坐落在郑州市航空港经济综合实验区",
          "2、家庭成员须依据实际情况填写配偶及未成年子女",
          "3、申请人需要在港区工作或创业",
          "4、工作单位需注册地为郑州市航空港经济综合实验区",
        ],
        high_talent_devote: ["1、工作单位需注册地为郑州市航空港经济综合实验区"],
        talent_house_apply: [
          // "1、家庭成员为配偶及未成年子女",
          "1、请完整填写在工商局注册的单位全称",
        ],
        young_life_subsidy: [
          "1、请完整填写在工商局注册的工作单位全称",
          "2、当前只受理申请人为非全日制硕博人才。",
        ],
        edu_health_house_subsidy: [
          "1、购买住房地址需坐落在郑州市航空港经济综合实验区",
          "2、家庭成员须依据实际情况填写配偶及未成年子女",
          "3、申请人需在港区工作或创业并完整填写在工商局注册的单位全称",
        ],
        industry_house_subsidy: [
          "1、购买住房地址需坐落在郑州市航空港经济综合实验区",
          "2、家庭成员须依据实际情况填写配偶及未成年子女",
          "3、申请人需在港区工作或创业并完整填写在工商局注册的单位全称",
        ],
      },
      form: null, //表单对象
      enable: true, //事项是否能够办理
      reFormId: "", //重复提交业务id
      defaultForm: null, //初始表单
      loaded: false,
      isLoading: false, //加载...
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
    defaultMaterialList() {
      //提交
      if (!this.reFormId) {
        return this.materialList;
      }
      //重新提交
      if (this.reFormId) {
        let result = [];
        this.materialList.forEach((item) => {
          let fileItem = { ...item };
          let hisItem =
            this.hisMaterialList.filter(
              (a) => a.applyMaterialId === item.id
            )[0] || {};
          fileItem.applyMaterialId = hisItem.applyMaterialId || item.id;
          fileItem.files = hisItem.fileUrls || [];

          result.push(fileItem);
        });
        return result;
      }
    },
  },
  components: {
    ...templates,
    VanFieldUpload,
  },
  created() {
    this.initId();
    if (this.id) {
      this.getDetail();
    }
    //重复提交的form
    if (this.reFormId) {
      this.getReFormDetail();
    }
  },
  mounted() {},
  methods: {
    //重新提交
    getReFormDetail() {
      getApplyDetailInfo({
        bizCode: this.reFormId,
        // formKey: this.detail.formKey
      }).then((res) => {
        this.defaultForm = {
          ...JSON.parse(res.data.formValue),
        };
        this.hisMaterialList = res.data.applyFileList || [];
        this.loaded = true;
        // let { data, success } = res;
        // if (data && success) {
        //   let formValue = JSON.parse(data.formValue);
        //   this.demoForm.map(item => {
        //     return (item.value = formValue[item.field] || "");
        //   });
        //   this.materialList = data.applyFileList.map(e => {
        //     return {
        //       files: e.fileUrls,
        //       applyMaterialId: e.applyMaterialId
        //     };
        //   });
        // }
      });
    },
    handleNext() {
      this.$refs.form.validate().then((form) => {
        this.form = form;
        this.active = 1;
      });
    },
    handleSubmit() {
      let isFilesOk = this.$refs["uploadForm"].validateMaterialList();
      if (isFilesOk) {
        const materiaForm = this.$refs["uploadForm"].materialList.map((el) => {
          return {
            applyMaterialId: el.applyMaterialId,
            materialCode: el.files.map((file) => file.objectKey).join(","),
          };
        });
        const data = {
          itemId: this.id,
          formValue: JSON.stringify(this.form),
          materialList: materiaForm,
          contactCard: this.userInfo.idCard,
          contactPhe: this.userInfo.usrPhe,
          contactUsr: this.userInfo.rnName,
          formKey: this.detail.formKey,
          type: "2", //个人
          legalPerson: "",
          cmpyCode: "",
          cmpyName: "",
          creditCode: "",
          itemName: "",
        };
        this.isLoading = true;

        postApply(data)
          .then((res) => {
            this.isLoading = false;
            this.$dialog.alert({
              title: "提交成功",
              message: `处理单号：${res.data}`,
              beforeClose: (action, done) => {
                done();
                this.$router.replace("/applylist");
              },
            });
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    initId() {
      const { id } = this.$route.params;
      const { r } = this.$route.query;
      if (id) this.id = id;
      if (r) this.reFormId = r;
    },
    //办事项详情
    getDetail() {
      getApplyInfo({
        itemId: this.id,
      }).then((res) => {
        this.detail = res.data;
        this.materialList = this.detail.applyMaterialList.map((e) => {
          return {
            ...e,
            applyMaterialId: e.id,
            files: [],
          };
        });

        this.componentId = res.data.formKey.replaceAll("_", "-");
        if (!this.reFormId) {
          this.loaded = true;
        }
      });
    },
    // getFormDetail() {
    //   getFormDetail({
    //     formId: res.data.formId,
    //   }).then((res) => {
    //     // this.formRules = res.data.formContentConvert;
    //   });
    // },
    /*
     *@des: 监听事项权限资格检查
     */
    handleAuthCheck(val) {
      this.enable = !!val;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  padding: 0.2rem 0.3rem 0.2rem;
  background: #f9f9f9;
  overflow: auto;
  .page-title {
    font-size: 0.32rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
  }
  /deep/ .notices {
    margin-top: 0.2rem;
    padding: 0.3rem;
    background-color: #fff;
    .notice-item {
      font-size: 0.24rem;
      line-height: 0.3rem;
      color: #ff0000;
    }
  }
}
.btns {
  margin: 0.3rem 0;
  display: flex;
}
</style>
