<!--
*@des: 办事项-材料上传
-->
<template>
  <section class="apply-upload">
    <van-divider
      content-position="left"
      :style="{ color: '#1989fa', borderColor: '#1989fa' }"
      >附件材料</van-divider
    >

    <van-empty v-if="!fileList.length" description="无需材料"></van-empty>

    <div v-else>
      <div
        class="upload-item"
        v-for="(item, i) in fileList"
        :key="item.itemId + item.id"
      >
        <div class="item-title">
          <p class="title">
            <span class="file-name">
              {{ i + 1 + "." + item.materialName }}
            </span>
            <span v-if="item.materialNeed == 1" style="color: #ff0000">*</span>
          </p>
          <div @click="onBeforeRead(i)">
            <van-uploader
              :after-read="onAfterRead"
              :max-size="uploadSize * 1024 * 1024"
              @oversize="onOversize"
            >
              <van-button
                round
                type="primary"
                size="mini"
                style="padding: 0px 10px"
                >上传文件</van-button
              >
            </van-uploader>
          </div>
        </div>
        <div class="item-files">
          <div
            class="file-item"
            v-for="(sub, b) in item.files"
            :key="sub.objectKey + sub.fileName + b"
          >
            <p class="name">{{ sub.fileName }}</p>
            <div class="opt-box">
              <p class="down-btn" @click="downLoadFile(sub.url, sub.fileName)">
                下载
              </p>
              <p class="delete-btn" @click="handleDelete(b, i)">删除</p>
            </div>
          </div>
        </div>
        <div class="item-sampleurl">
          <!-- @click="downLoadFile(main.url, main.fileName)" -->
          <span
            class="empty"
            v-for="main in item.blankChartUrls"
            :key="main.objectKey"
          >
            <a :href="main.url" :download="main.fileName">
              空表下载<van-icon name="down" />
            </a>
            <!-- 空表下载
            <van-icon name="down" /> -->
          </span>
          <!-- @click="downLoadFile(main.url, main.fileName)" -->
          <span
            class="empty"
            v-for="main in item.sampleChartUrls"
            :key="main.objectKey"
          >
            <a :href="main.url" :download="main.fileName">
              样表下载<van-icon name="down" />
            </a>
            <!-- 样表下载
            <van-icon name="down" /> -->
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { uploadFile } from "@/api/policy/apply";
import urlblobDownload from "@/utils/download";

export default {
  name: "van-field-upload",
  props: {
    materialList: Array,
    uploadSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      fileList: [],
      uploadIndex: 0,
    };
  },
  watch: {
    materialList: {
      handler: function (newval) {
        this.initFileList();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /*
     *@des: 重置材料数据
     */
    initFileList() {
      this.fileList = this.materialList;
    },
    /*
     *@des: 文件下载
     */
    downLoadFile(url, fileName) {
      urlblobDownload(url, fileName);
    },
    /*
     *@des: 上传前
     */
    onBeforeRead(index) {
      this.uploadIndex = index;
    },
    onOversize(file) {
      this.$toast("文件大小不能超过 2M");
    },
    /*
     *@des: 文件上传
     */
    onAfterRead({ file }) {
      let type = file.type ? file.type.split("/")[1] : "";
      let types = ["png", "jpg", "jpeg"];
      if (types.indexOf(type) < 0) {
        this.$toast("请上传图片!");
        return;
      }

      let item = this.materialList[this.uploadIndex];
      let params = new FormData();
      params.append("multipartFile", file);

      this.$toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });
      uploadFile(params)
        .then((res) => {
          let { success, data } = res;
          if (success) {
            item.files.push({
              ...data,
              applyMaterialId: item.id,
            });
            this.$toast("上传成功");
            this.$toast.clear();
          } else {
            this.$toast("上传失败");
          }
        })
        .catch((e) => this.$toast("上传失败"));
    },
    /*
     *@des: 删除材料
     */
    handleDelete(b, i) {
      this.materialList[i].files.splice(b, 1);
    },
    /*
     *@des: 校验材料
     */
    validateMaterialList() {
      let fileStatus = true;
      this.fileList.forEach((item, i) => {
        //判断文件必传，文件长度
        let { materialName, materialNeed } = item;
        if (!item.files.length && materialNeed == 1) {
          this.$toast(`请上传${materialName},不可为空`);
          fileStatus = false;
        }
      });
      return fileStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.apply-upload {
  margin: 0.3rem 0rem;
  padding: 0.3rem;
  border-radius: 0.15rem;
  background-color: #fff;
  .upload-item {
    background: #fff;
    margin-bottom: 0.45rem;
    .hr {
      width: 6.3rem;
      height: 0.01rem;
      background: #e5e5e5;
      margin-top: 0.2rem;
    }
    .item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        line-height: 0.66rem;
        font-size: 0.32rem;
        color: #333333;
        width: 4.4rem;
        span.file-name {
          display: inline-block;
          vertical-align: middle;
          max-width: 4.2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .upload-btn {
        width: 1.8rem;
        height: 0.6rem;
        background: #3c37d2;
        border-radius: 0.5rem;
        color: #fff;
        line-height: 0.6rem;
        text-align: center;
        font-size: 0.24rem;
        font-weight: 400;
      }
    }
    .item-files {
      padding: 0.15rem 0rem;
      border-bottom: 1px solid #eee;
      .file-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.1rem;
        .name {
          width: 2.86rem;
          height: 0.32rem;
          font-size: 0.26rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.32rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .opt-box {
          display: flex;
          width: 1.3rem;
          .delete-btn {
            font-size: 0.26rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.32rem;
          }
          .down-btn {
            font-size: 0.26rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.32rem;
            margin-right: 0.2rem;
          }
        }
      }
    }
    .item-sampleurl {
      display: flex;
      height: 0.6rem;
      .empty {
        margin-right: 0.4rem;
        line-height: 0.6rem;
        color: #3c37d2;
        font-size: 0.26rem;
      }
    }
  }
}
</style>
